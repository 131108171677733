import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Progress,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigation } from 'react-router'
import { Link as RouterLink, useMatches } from 'react-router-dom'

import { CurrentAccessLevel, getUserProfile, logout } from '../../helpers/auth'
import { getNavGroups } from '../../helpers/nav'
import logo from '../../images/logo.png'
import type { RouteMatchWithHandle } from '../../types'
import { Breadcrumb } from '../breadcrumb'

const navGroups = getNavGroups()

function UserNavItems() {
  const userProfile = getUserProfile()
  if (!userProfile) return null

  return (
    <Flex direction="column" mt={8}>
      <Text fontSize="xs" fontWeight="bold" textTransform="uppercase" color="primary.400">
        {userProfile.given_name} {userProfile.family_name}
      </Text>
      <Link
        alignSelf="flex-start"
        mt={3}
        onClick={() => {
          logout()
          window.location.reload()
        }}
      >
        <Flex alignItems="center">
          <Icon as={FontAwesomeIcon} icon={['fad', 'person-to-door']} boxSize={5} mr={2} color="blue.500" />
          <Text as="span">Log out</Text>
        </Flex>
      </Link>
    </Flex>
  )
}

function useUpdateDocumentTitle() {
  const location = useLocation()
  const matches = useMatches()

  const currentRoute = matches.find(
    (route): route is RouteMatchWithHandle => Boolean(route.handle) && route.pathname === location.pathname
  )
  const currentRouteTitle = currentRoute?.handle?.title?.(currentRoute.data)

  useEffect(() => {
    document.title = currentRouteTitle ? `${currentRouteTitle} - CLS+ Admin` : 'CLS+ Admin'
  }, [currentRouteTitle])
}

export function Layout() {
  const isSmallViewport = useBreakpointValue({ base: true, lg: false })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const navigation = useNavigation()
  useUpdateDocumentTitle()

  return (
    <>
      <Flex
        pos="sticky"
        direction="column"
        top={0}
        zIndex={10}
        h={16}
        alignItems="center"
        bg="primary.800"
        boxShadow={{ base: 'cls.standard', lg: 'none' }}
      >
        <Flex w="100%" justifyContent={{ base: 'center', lg: 'flex-start' }}>
          <Flex justifyContent={{ base: 'center', lg: 'flex-start' }} flex={{ base: 1, lg: '0 0 280px' }} py={3}>
            {isSmallViewport && (
              <IconButton
                pos="absolute"
                top={3}
                left={4}
                variant="secondary"
                aria-label="Navigation"
                icon={<FontAwesomeIcon icon={['far', 'bars']} />}
                onClick={onOpen}
              />
            )}
            <Link as={RouterLink} to="/match-centre">
              <Flex w="auto" justifyContent="flex-start" alignItems="center" py={1} px={6}>
                <Image src={logo} boxSize={8} mr={2} />
                <Text color="white" fontSize="lg" fontWeight="bold">
                  Mission Control
                </Text>
                <Box width="1.5" display="inline-block" />
                <Text color="white" fontSize="xs" fontWeight="thin">
                  {process.env.PACKAGE_VERSION}
                </Text>
              </Flex>
            </Link>
          </Flex>
          {!isSmallViewport && <Breadcrumb />}
        </Flex>
        <Progress
          pos="absolute"
          bottom={0}
          w="100%"
          size="xs"
          bg="transparent"
          isIndeterminate={navigation.state === 'loading'}
        />
      </Flex>
      <Flex bg="primary.800" h="calc(100vh - 64px)" overflowX="auto">
        {!isSmallViewport && (
          <Box pos="sticky" top={0} p={6} h="calc(100vh - 64px)" flex="0 0 280px" overflowY="auto">
            <Flex direction="column" justifyContent="space-between" h="100%">
              <Stack spacing={8}>
                {navGroups.map((navGroup, index) => {
                  if (navGroup.items.some(navItem => navItem.requiredAccess <= CurrentAccessLevel())) {
                    return (
                      <Flex key={`navGroup-${index}`} direction="column">
                        {navGroup.label && (
                          <Text fontSize="xs" fontWeight="bold" textTransform="uppercase" color="primary.400">
                            {navGroup.label}
                          </Text>
                        )}
                        {navGroup.items
                          .filter(navItem => navItem.requiredAccess <= CurrentAccessLevel())
                          .map(navItem => (
                            <Link key={navItem.href} as={RouterLink} to={navItem.href} alignSelf="flex-start" mt={3}>
                              <Flex alignItems="center">
                                <Icon as={FontAwesomeIcon} icon={navItem.icon} boxSize={5} mr={3} color="blue.500" />
                                <Text as="span">{navItem.label}</Text>
                              </Flex>
                            </Link>
                          ))}
                      </Flex>
                    )
                  }
                })}
              </Stack>
              <UserNavItems />
            </Flex>
          </Box>
        )}
        <Flex
          flex={1}
          bg="primary.700"
          borderTopLeftRadius={{ base: 'none', lg: '6px' }}
          boxShadow="cls.standard"
          overflowY="auto"
          px={6}
          pt={6}
        >
          <Flex
            flex={1}
            direction="column"
            alignItems="flex-start"
            maxW="container.xl"
            // This is a bit of a hack to add some bottom padding to the last
            // child rendered by the outlet. This is necessary because overflow
            // containers collapse the bottom margin/padding of their last child.
            sx={{ '> :last-child': { pb: 6 } }}
          >
            {isSmallViewport && <Breadcrumb mb={6} />}
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
      <Drawer placement="left" onClose={onClose} isOpen={!!isSmallViewport && isOpen}>
        <DrawerOverlay />
        <DrawerContent bg="primary.800">
          <DrawerCloseButton top={5} right={5} _hover={{ bg: 'primary.700' }} />
          <DrawerHeader>
            <Flex w="auto" justifyContent="flex-start" alignItems="center" py={1}>
              <Image src={logo} boxSize={8} mr={2} />
              <Text fontSize="lg" fontWeight="bold" color="white">
                Mission Control
              </Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody p={6}>
            <Flex direction="column" justifyContent="space-between" minH="100%">
              <Stack spacing={8}>
                {navGroups.map((navGroup, index) => (
                  <Flex key={`navGroup-${index}`} direction="column">
                    {navGroup.label && (
                      <Text fontSize="xs" fontWeight="bold" textTransform="uppercase" color="primary.400">
                        {navGroup.label}
                      </Text>
                    )}
                    {navGroup.items.map(navItem => (
                      <Link
                        key={navItem.href}
                        as={RouterLink}
                        to={navItem.href}
                        alignSelf="flex-start"
                        mt={3}
                        onClick={onClose}
                      >
                        <Flex alignItems="center">
                          <Icon as={FontAwesomeIcon} icon={navItem.icon} boxSize={5} mr={2} color="blue.500" />
                          <Text as="span">{navItem.label}</Text>
                        </Flex>
                      </Link>
                    ))}
                  </Flex>
                ))}
              </Stack>
              <UserNavItems />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
